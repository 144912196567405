/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Context from "../../Context/Context";

import { ContainerInformationLeft } from "../../components/ContainerInformationLeft/style";
import { ContainerPurchaseResume } from "../../components/ContainerPurchaseResume/style";
import { GeneralContainerPages } from "../../components/GeneralContainerPages/style";
import { ContainerTextPurchase } from "../../components/ContainerTextPurchase/style";
import ButtonChoosePaymentMethod from "../../components/ButtonChoosePaymentMethod";
import { ContainerCourseImage } from "../../components/ContainerCourseImage/style";
import {
  ContainerSecondStrip,
  IconsHeader,
} from "../../components/Header/style";
import ContainerInformations from "../../components/ContainerInformations/style";
import { GeneralContainer } from "../../components/GeneralContainer/style";
import { ContainerPrice } from "../../components/ContainerPrice/style";
import ContainerIcons from "../../components/ContainerIcons/style";
import { IconGapLine } from "../../components/IconGapLine/style";
import IconsPurchase from "../../components/IconPurchase/style";
import { ResumeText } from "../ChekoutResolva/style";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import CreditCardIconSVG from "../../assets/images/checkout-icons/credit-card-icon.svg";
import CardIconSVG from "../../assets/images/checkout-icons/credit-card-icon.svg";
import ResolvaIconSVG from "../../assets/images/header-icons/resolva-icon.svg";
import PixIconSVG from "../../assets/images/checkout-icons/pix-icon.svg";
import secondIcon from "../../assets/images/second-icon.svg";
import firstIcon from "../../assets/images/first-icon.svg";
import thirdIcon from "../../assets/images/third-icon.svg";

export default function ChoosePayment() {
  const { courseData } = useContext(Context);

  const { courseId, courseTitle } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (courseData.title === "") {
      navigate(`/checkout-resolva/${courseId}/${courseTitle}`);
    }
  }, []);

  const definePixPrice = () => {
    if (courseTitle === "Mãe-com-Estilo-Sua-nova-MELHOR-versão-Turma-Abril-25")
      return 239.6;

    return (
      Number(courseData.currentValue) - Number(courseData.currentValue) * 0.1
    );
  };

  if (
    courseTitle === "Mãe-com-Estilo-Sua-nova-MELHOR-versão-Turma-Café-com-a-Deh"
  )
    return <h2>LINK DESATIVADO</h2>;

  return (
    <GeneralContainerPages>
      <Header display="none" />
      <GeneralContainer>
        <div>
          <ContainerSecondStrip
            display="flex"
            displayMobile="none"
            alt="logo desktop"
          >
            <IconsHeader
              src={ResolvaIconSVG}
              mediaWidth="3.2vw"
              mediaHeight="4.5vw"
            />
            CHECKOUT DO RESOLVA
          </ContainerSecondStrip>
          <ContainerIcons displayMobile="none" display="flex">
            <IconsPurchase src={firstIcon} mediaWidth="7.8vw" />
            <IconGapLine />
            <IconsPurchase src={secondIcon} mediaWidth="9.2vw" />
            <IconGapLine opacity="0.1" />
            <IconsPurchase
              style={{ opacity: "0.2" }}
              src={thirdIcon}
              mediaWidth="9.2vw"
            />
          </ContainerIcons>
        </div>
        <ContainerInformations>
          <ContainerPurchaseResume>
            <ResumeText>Resumo da Compra</ResumeText>
            <ContainerCourseImage backImage={courseData.courseImage} />
            <ContainerTextPurchase>
              <h3>{courseData.title}</h3>
            </ContainerTextPurchase>
            <ContainerTextPurchase bottomDesktop="0">
              <h3>Online</h3>
            </ContainerTextPurchase>
            <ContainerTextPurchase
              height="10vh"
              flexDirection="row"
              top="0"
              height2="6vh"
              align="flex-end"
              padTop="5.733vw"
              leftMobile="1.226vw"
              padTopDesktop="1.281vw"
              leftDesktop="0.5vw"
              bottomDesktop="0vw"
              alignDesktop="flex-end"
            >
              <IconsPurchase
                src={CreditCardIconSVG}
                width="5.4vw"
                height="4.2vw"
                mediaWidth="1.265vw"
                mediaHeight="0.984vw"
                bottomMobile="5.866vw"
                leftMobile="1.093vw"
                bottomDesktop="1.25vw"
                left="0.343vw"
              />
              <p>Cartão de Crédito</p>
              <ContainerPrice>
                <span>
                  {Number(courseData.currentValue)?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </ContainerPrice>
            </ContainerTextPurchase>
            <ContainerTextPurchase
              height="10vh"
              flexDirection="row"
              top="0"
              padTop="5.733vw"
              height2="6vh"
              align="flex-end"
              leftMobile="1.866vw"
              padTopDesktop="1.281vw"
              leftDesktop="0.562vw"
              bottomDesktop="0vw"
              alignDesktop="flex-end"
            >
              <IconsPurchase
                src={PixIconSVG}
                width="4.794vw"
                height="4.789vw"
                mediaWidth="1.123vw"
                mediaHeight="1.122vw"
                bottomMobile="5.866vw"
                leftMobile="1.413vw"
                bottomDesktop="1.25vw"
                left="0.343vw"
              />
              <p>PIX</p>
              <ContainerPrice>
                <h2>
                  {Number(courseData.originalValue)?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </h2>
                <span>
                  {definePixPrice()?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </ContainerPrice>
            </ContainerTextPurchase>
          </ContainerPurchaseResume>
          <ContainerIcons display="none">
            <IconsPurchase src={firstIcon} />
            <IconGapLine />
            <IconsPurchase src={secondIcon} />
            <IconGapLine opacity="0.1" />
            <IconsPurchase style={{ opacity: "0.2" }} src={thirdIcon} />
          </ContainerIcons>
          <ContainerInformationLeft>
            <ContainerTextPurchase
              fontSize="1.375rem"
              border="none"
              height2="10vh"
              bottomDesktop="1.25vw"
              widthDesktop="19.25vw"
              widthMobile="82.133vw"
              fontWeightDesktop="400"
              fontWeightMobile="400"
              marginTopMobile="1.6vw"
              padBottomDesktop="0"
            >
              Pagamento
              <p>Escolha uma forma de pagamento</p>
            </ContainerTextPurchase>
            <ButtonChoosePaymentMethod
              src={CardIconSVG}
              widthIcon="5.4vw"
              heightIcon="4.2vw"
              widthIconMedia="1.25vw"
              heightIconMedia="1.125vw"
              text="Cartão de Crédito"
              navigateUrl={`/tipo-de-pagamento/${courseId}/${courseTitle}/credit-card`}
            />
            <ButtonChoosePaymentMethod
              src={PixIconSVG}
              text="PIX"
              widthIcon="4.792vw"
              heightIcon="4.792vw"
              widthIconMedia="1.125vw"
              heightIconMedia="1.125vw"
              navigateUrl={`/tipo-de-pagamento/${courseId}/${courseTitle}/pix`}
            />
          </ContainerInformationLeft>
        </ContainerInformations>
      </GeneralContainer>
      <Footer />
    </GeneralContainerPages>
  );
}
