/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

import {
  checkCpf,
  checkName,
  checkBirthDate,
} from "../../functions/validatePersonalDataForm";
import Context from "../../Context/Context";
import { apiGlobal } from "../../service/api";

import { ContainerInformationLeft } from "../../components/ContainerInformationLeft/style";
import { ContainerPurchaseResume } from "../../components/ContainerPurchaseResume/style";
import { GeneralContainerPages } from "../../components/GeneralContainerPages/style";
import { ContainerCourseImage } from "../../components/ContainerCourseImage/style";
import { ContainerTextPurchase } from "../../components/ContainerTextPurchase/style";
import ContainerInformations from "../../components/ContainerInformations/style";
import { ContainerPrice } from "../../components/ContainerPrice/style";
import ContainerIcons from "../../components/ContainerIcons/style";
import { IconGapLine } from "../../components/IconGapLine/style";
import IconsPurchase from "../../components/IconPurchase/style";
import { ErrorMsg } from "../../components/ErrorMsg/style";
import GeneralInputText from "../../components/InputText";
import invertDate from "../../functions/invertDate";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Select from "../../components/Select";

import {
  ContainerSecondStrip,
  IconsHeader,
} from "../../components/Header/style";

import {
  ButtonOkCep,
  ContainerSideBySide,
  FormContainer,
  ResumeText,
} from "./style";

import CreditCardIconSVG from "../../assets/images/checkout-icons/credit-card-icon.svg";
import ResolvaIconSVG from "../../assets/images/header-icons/resolva-icon.svg";
import PixIconSVG from "../../assets/images/checkout-icons/pix-icon.svg";
import secondIcon from "../../assets/images/second-icon.svg";
import firstIcon from "../../assets/images/first-icon.svg";
import thirdIcon from "../../assets/images/third-icon.svg";

export default function CheckoutResolva() {
  const { formInfo, setFormInfo, courseData, setCourseData } =
    useContext(Context);

  const [errorMsg, setErrorMsg] = useState("");
  const [disable, setDisable] = useState(true);
  const [visibility, setVisibility] = useState("hidden");
  const [loadingCep, setLoadingCep] = useState(false);

  const { courseId, courseTitle } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const courseDataResponse = await apiGlobal.getCourseAcademyData(
          courseId
        );
        setCourseData(courseDataResponse);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Ocorreu um erro",
          text: "Ocorreu um erro ao recuperar os dados do curso, por favor entre em contato com a nossa equipe.",
          iconColor: "black",
          confirmButtonColor: "black",
        });
      }
    })();
  }, []);

  const filledForm = () => {
    const values = Object.entries(formInfo);

    const fieldsIsValid = values.some(
      (element) => element[0] !== "complement" && !element[1]
    );
    if (!fieldsIsValid) return false;
    return true;
  };

  const handleCepValidation = async () => {
    if (formInfo.zipcode.length < 9) {
      alert("Preencha o cep corretamente. Ex.: 99999-999");
      return;
    }
    setLoadingCep(true);
    const { data } = await axios.get(
      `https://viacep.com.br/ws/${formInfo.zipcode}/json/`
    );
    if (data.erro) {
      setLoadingCep(false);
      return;
    }
    if (data) {
      setFormInfo((prev) => ({
        ...prev,
        neighborhood: data.bairro,
        city: data.localidade,
        state: data.uf,
        street: data.logradouro,
      }));
      setLoadingCep(false);
      setDisable(false);
    }
    setLoadingCep(false);
  };

  const handleInputChange = ({ target }) =>
    setFormInfo({ ...formInfo, [target.name]: target.value });

  const inputValidation = (message) => {
    setVisibility("block");
    setErrorMsg(message);
    setTimeout(() => {
      setVisibility("hidden");
    }, 5000);
  };

  const generalInputValidation = (nameIsValid, cpfIsValid, birthdayIsValid) => {
    if (!nameIsValid) {
      inputValidation("Digite o seu nome completo");
      return false;
    }
    if (!cpfIsValid) {
      inputValidation("CPF inválido");
      return false;
    }
    if (!birthdayIsValid) {
      inputValidation("Data de nascimento inválida");
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMsg("");
    const nameIsvalid = checkName(formInfo.customerName);
    const cpfIsValid = checkCpf(
      formInfo.customerCpf.replace(/[^a-zA-Z0-9]/g, "")
    );
    const birthdayIsValid = checkBirthDate(formInfo.customerBirthday);

    if (!generalInputValidation(nameIsvalid, cpfIsValid, birthdayIsValid))
      return;

    setErrorMsg("");

    const formatedObj = {
      customerEmail: formInfo.customerEmail,
      customerCpf: formInfo.customerCpf.replace(/[^a-zA-Z0-9]/g, ""),
      customerBirthday: invertDate(formInfo.customerBirthday),
      customerPhoneNumber: `${formInfo.customerPhoneNumber
        .replace(/[^a-zA-Z0-9]/g, "")
        .replace(" ", "")}`,
      customerName: formInfo.customerName,
      state: formInfo.state,
      city: formInfo.city,
      neighborhood: formInfo.neighborhood,
      street: formInfo.street,
      streetNumber: formInfo.streetNumber,
      complement: formInfo.complement.length
        ? formInfo.complement
        : "Sem complemento",
      zipcode: formInfo.zipcode.replace("-", ""),
      customerOccupation: formInfo.customerOccupation,
    };
    setFormInfo(formatedObj);

    if (window.location.href.split("?")[1]) {
      navigate(
        `/escolha-o-pagamento/${courseId}/${courseTitle}?${
          window.location.href.split("?")[1]
        }`
      );
    } else {
      navigate(`/escolha-o-pagamento/${courseId}/${courseTitle}`);
    }
  };

  const definePixPrice = () => {
    if (courseTitle === "Mãe-com-Estilo-Sua-nova-MELHOR-versão-Turma-Abril-25")
      return 239.6;

    return (
      Number(courseData.currentValue) - Number(courseData.currentValue) * 0.1
    );
  };

  if (
    courseTitle === "Mãe-com-Estilo-Sua-nova-MELHOR-versão-Turma-Café-com-a-Deh"
  )
    return <h2>LINK DESATIVADO</h2>;

  return (
    <GeneralContainerPages>
      <Header display="none" />
      <FormContainer onSubmit={(e) => handleSubmit(e)}>
        <div>
          <ContainerSecondStrip
            display="flex"
            displayMobile="none"
            alt="logo desktop"
          >
            <IconsHeader
              src={ResolvaIconSVG}
              mediaWidth="3.2vw"
              mediaHeight="4.5vw"
            />
            CHECKOUT DO RESOLVA
          </ContainerSecondStrip>
          <ContainerIcons displayMobile="none" display="flex">
            <IconsPurchase src={firstIcon} mediaWidth="7.8vw" />
            <IconGapLine opacity="0.1" />
            <IconsPurchase
              style={{ opacity: "0.2" }}
              src={secondIcon}
              mediaWidth="9.2vw"
            />
            <IconGapLine opacity="0.1" />
            <IconsPurchase
              style={{ opacity: "0.2" }}
              src={thirdIcon}
              mediaWidth="9.2vw"
            />
          </ContainerIcons>
        </div>
        <ContainerInformations>
          <ContainerPurchaseResume>
            <ResumeText>Resumo da Compra</ResumeText>
            <ContainerCourseImage backImage={courseData.courseImage} />
            <ContainerTextPurchase>
              <h3>{courseData.title}</h3>
            </ContainerTextPurchase>
            <ContainerTextPurchase bottomDesktop="0">
              <h3>Online</h3>
            </ContainerTextPurchase>
            <ContainerTextPurchase
              height="10vh"
              flexDirection="row"
              top="0"
              height2="6vh"
              align="flex-end"
              padTop="5.733vw"
              leftMobile="1.226vw"
              padTopDesktop="1.281vw"
              leftDesktop="0.5vw"
              bottomDesktop="0vw"
              alignDesktop="flex-end"
            >
              <IconsPurchase
                src={CreditCardIconSVG}
                width="5.4vw"
                height="4.2vw"
                mediaWidth="1.265vw"
                mediaHeight="0.984vw"
                bottomMobile="5.866vw"
                leftMobile="1.093vw"
                bottomDesktop="1.25vw"
                left="0.343vw"
              />
              <p>Cartão de Crédito</p>
              <ContainerPrice>
                <h2>
                  {Number(courseData.originalValue)?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </h2>
                <span>
                  {Number(courseData.currentValue)?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </ContainerPrice>
            </ContainerTextPurchase>
            <ContainerTextPurchase
              height="10vh"
              flexDirection="row"
              top="0"
              padTop="5.733vw"
              height2="6vh"
              align="flex-end"
              leftMobile="1.866vw"
              padTopDesktop="1.281vw"
              leftDesktop="0.562vw"
              bottomDesktop="0vw"
              alignDesktop="flex-end"
            >
              <IconsPurchase
                src={PixIconSVG}
                width="4.794vw"
                height="4.789vw"
                mediaWidth="1.123vw"
                mediaHeight="1.122vw"
                bottomMobile="5.866vw"
                leftMobile="1.413vw"
                bottomDesktop="1.25vw"
                left="0.343vw"
              />
              <p>PIX</p>
              <ContainerPrice>
                <h2>
                  {Number(courseData.originalValue)?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </h2>
                <span>
                  {Number(definePixPrice())?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </span>
              </ContainerPrice>
            </ContainerTextPurchase>
            <ErrorMsg visibility={visibility} display="flex">
              {errorMsg}
            </ErrorMsg>
            <Button
              text="CONTINUAR"
              backColor="#E5E5E5"
              alt="Botão DESKTOP"
              displayMobile="none"
              display="flex"
              type="submit"
              disabled={filledForm()}
              width="21.437vw"
            ></Button>
          </ContainerPurchaseResume>
          <ContainerIcons display="none">
            <IconsPurchase src={firstIcon} width="18vw" />
            <IconGapLine opacity="0.1" />
            <IconsPurchase
              style={{ opacity: "0.2" }}
              src={secondIcon}
              width="21.2vw"
            />
            <IconGapLine opacity="0.1" />
            <IconsPurchase
              style={{ opacity: "0.2" }}
              src={thirdIcon}
              width="21.2vw"
            />
          </ContainerIcons>
          <ContainerInformationLeft>
            <ContainerTextPurchase
              fontSize="1.375rem"
              border="none"
              height2="10vh"
              bottomDesktop="1.25vw"
              widthDesktop="19.25vw"
              widthMobile="82.133vw"
              fontWeightDesktop="400"
              fontWeightMobile="400"
              marginTopMobile="1.6vw"
              padBottomDesktop="0"
            >
              Formulário de Compra
              <p>
                Estamos muito felizes com a sua inscrição! Nos conte um
                pouquinho mais sobre você?
              </p>
            </ContainerTextPurchase>
            <GeneralInputText
              placeholder="Ex. Joana Mariano"
              text="Nome Completo"
              value={formInfo.customerName}
              name="customerName"
              errorMsg="Digite o seu nome completo"
              handleInputChange={handleInputChange}
            />
            {courseTitle === "Curso-de-Imagem-Profissional" && (
              <GeneralInputText
                placeholder="Ex. Consultora de Imagem"
                text="Profissão"
                value={formInfo.customerOccupation}
                name="customerOccupation"
                errorMsg="Digite sua profissão"
                handleInputChange={handleInputChange}
              />
            )}
            <GeneralInputText
              placeholder="Ex. xxx.xxx.xxx-xx"
              text="CPF"
              pattern=".{14,}"
              value={formInfo.customerCpf}
              name="customerCpf"
              errorMsg="Digite um CPF válido"
              handleInputChange={handleInputChange}
            />
            <GeneralInputText
              placeholder="Ex. 01/12/1987"
              pattern=".{10,}"
              text="Data de Nascimento"
              value={formInfo.birthday}
              name="customerBirthday"
              errorMsg="Digite uma data de nascimento válida"
              handleInputChange={handleInputChange}
            />
            <GeneralInputText
              placeholder="joanamariano@gmail.com"
              text="Email"
              value={formInfo.customerEmail}
              name="customerEmail"
              type="email"
              errorMsg="Digite um email válido"
              handleInputChange={handleInputChange}
            />
            <ContainerSideBySide>
              <GeneralInputText
                placeholder="00000-000"
                text="CEP"
                width="73.066vw"
                mediaHeight="3vw"
                mediaWidth="23.1vw"
                value={formInfo.zipcode}
                name="zipcode"
                pattern=".{9,}"
                errorMsg="Digite um CEP válido"
                handleInputChange={handleInputChange}
                handleCepValidation={handleCepValidation}
                loadingCep={loadingCep}
              />
              <ButtonOkCep type="button">OK</ButtonOkCep>
            </ContainerSideBySide>
            <GeneralInputText
              placeholder="Rua, Avenida, etc"
              text="Endereço"
              value={formInfo.street}
              disable={disable}
              name="street"
              handleInputChange={handleInputChange}
            />
            <ContainerSideBySide>
              <GeneralInputText
                placeholder="Ex. 100"
                text="Número"
                value={formInfo.streetNumber}
                disable={disable}
                mediaWidth="13.1vw"
                name="streetNumber"
                handleInputChange={handleInputChange}
              />
              <GeneralInputText
                placeholder="Ex. apto 203"
                text="Complemento"
                mediaWidth="13.1vw"
                value={formInfo.complement}
                name="complement"
                handleInputChange={handleInputChange}
              />
            </ContainerSideBySide>
            <GeneralInputText
              placeholder="Ex. Morumbi"
              text="Bairro"
              value={formInfo.neighborhood}
              disable={disable}
              name="neighborhood"
              handleInputChange={handleInputChange}
            />
            <ContainerSideBySide>
              <GeneralInputText
                placeholder="São Paulo"
                text="Cidade"
                width="54vw"
                mediaHeight="3vw"
                mediaWidth="13.1vw"
                value={formInfo.city}
                disable={disable}
                name="city"
                handleInputChange={handleInputChange}
              />
              <Select
                placeholder="SP"
                text="Estado"
                width="29.5vw"
                mediaHeight="3vw"
                mediaWidth="13.1vw"
                value={formInfo.state}
                disable={disable}
                name="state"
                handleInputChange={handleInputChange}
              />
            </ContainerSideBySide>
            <GeneralInputText
              placeholder="Ex. (11) 9 99999999"
              text="Whatsapp"
              pattern=".{15,}"
              value={formInfo.customerPhoneNumber}
              disable={disable}
              name="customerPhoneNumber"
              errorMsg="Digite um número de celular válido"
              handleInputChange={handleInputChange}
            ></GeneralInputText>
            <ErrorMsg visibility={visibility} display="none">
              {errorMsg}
            </ErrorMsg>
            <Button
              text="CONTINUAR"
              backColor="#E5E5E5"
              display="none"
              alt="Botão MOBILE"
              disabled={filledForm()}
              type="submit"
            />
          </ContainerInformationLeft>
        </ContainerInformations>
      </FormContainer>
      <Footer />
    </GeneralContainerPages>
  );
}
